<section 
  class="create-note-wrapper"
  [ngClass]="{
    'only--button' : onlyButton,
    'mini--button' : miniButton,
  }"
  *ngIf="(showBlock && isLoggedIn) || (onlyButton && isLoggedIn)|| (miniButton && isLoggedIn)">
  <a (click)="hideBlock()" class="hide-block" title="Hide this message box.">Close</a>
  <div class="info">
    <h2 class="info__title">Welcome to notd</h2>
    <p class="info__text">
      Learn <a [routerLink]="['s', 'notdnotes']" title="Learn about Notd">about Notd</a> and start creating your content <a [routerLink]="['note']" title="Create your content now">now</a>.
    </p>
  </div>
  <button
    *ngIf="onlyButton && !miniButton"
    class="btn new-note-btn btn-block"
    data-cy="new-note"
    title="Create new note"
    (click)="createNewNote()"
    [disabled]="disableNewNoteButton"
    [ngClass]="{'button-spinner' : loadingNoteCreator}"
    i18n="@@GLOBAL-NEW-NOTE">New Note</button>
  <button
    class="create-note-wrapper__mini--button"
    (click)="createNewNote()"
    [disabled]="disableNewNoteButton"
    [ngClass]="{'button-spinner' : loadingNoteCreator}"
    *ngIf="miniButton">
    <svg *ngIf="!loadingNoteCreator" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M16,24c-0.5527344,0-1-0.4472656-1-1V9c0-0.5527344,0.4472656-1,1-1s1,0.4472656,1,1v14    C17,23.5527344,16.5527344,24,16,24z"/></g><g><path d="M23,17H9c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h14c0.5527344,0,1,0.4472656,1,1S23.5527344,17,23,17z"/></g></g></svg>
  </button>
</section>