import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../shared/services/user_service';
import { StreamService } from '../shared/services/stream_service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Util } from '../shared/util/util';
import { NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { User } from '../shared/types/user';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { UsernameExistValidation } from '../shared/validators/username-exist.validator';
import { NonNumericValidator } from '../shared/validators/no-numeric.validator';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

export class UserComponent implements OnInit, OnDestroy {
  @ViewChild('userForm', {static: false}) public myUserForm: NgForm;
  myUser: User = new User();
  userLocation: any = {};
  routerEventsSubscription;
  userId: string;
  profileImageUrl;
  uploader;
  public saving = false;
  imgUserUrl: string;
  transactEmail = `${environment.transactBaseUrl}/user/edit_profile`;
  avatarMaxSizeKb = 3146000; // ~3mb 1048576
  avatarErrors = {
    limit: false,
    other: ''
  };
  preferences: any = {
    metricUnits: false,
    emailNotifications: false,
    notifyPostComments: false
  };
  alert: {type: string; message: string; timeout: number};
  thumbs: any;
  public enableLazyLoadingImgAvatar = true;
  public userForm: UntypedFormGroup;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private streamService: StreamService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private utilities: Util,
    private titleService: Title,
    private fb: UntypedFormBuilder,
    @Inject(PLATFORM_ID) protected platformId: any) {
  }

  public ngOnInit(): void {
    this.titleService.setTitle('My Account - notd.io');
    this.imgUserUrl = this.userService.getUserImagePath();

    const user = this.route.snapshot.data.user;
    this.myUser = Object.assign({}, user);
    this.myUser.profileImgThbs =
      (this.myUser.profileImgThbs && Object.keys(this.myUser.profileImgThbs).length === 0) ? null : this.myUser.profileImgThbs;
    this.profileImageUrl = user && user.profileImageUrl ? user.profileImageUrl : '';

    this.preferences.metricUnits = this.assignPreference('metricUnits', user);
    this.preferences.emailNotifications = this.assignPreference('emailNotifications', user);
    this.preferences.notifyPostComments = this.assignPreference('notifyPostComments', user);

    if (user.address) {
      this.userLocation = user.address;
    }

    this.createUserForm();
  }

  savedUserAvatar(e: any) {
    this.avatarErrors.limit = false;
    this.avatarErrors.other = '';

    const newImg = e['dataURL'];

    fetch(newImg)
      .then(res => res.blob())
      .then(blob => {
        const f = new File([blob], e.name, {type: blob.type});

        this.utilities.downscaleImage(f, 1146000).then(downscaledImg => {
          this.myUser.profileImageUrl = newImg;

          this.userService.uploadUserAvatar(downscaledImg).then(() => {
            this.myUser.profileImageUrl = newImg;

            if (this.enableLazyLoadingImgAvatar) {
              this.userService.getProfile(true).then((user: User) => {
                this.myUser.profileImgThbs = user.profileImgThbs;
                this.myUser.profileImageUrl = user.profileImageUrl;
              });
            }
          }, err => {
            console.log('err', err);
            if (err.error && err.error.indexOf('File too large') !== -1) {
              this.avatarErrors.limit = true;
            }
          })
          .catch(err => {
            this.avatarErrors.other = err;
          });
        });
      });
  }

  geoLocatorEvent(event: Event) {
    console.log('geoLocatorEvent', event);
    this.userLocation = Object.assign(this.userLocation, event);
  }

  public toggleDistanceEvent(event: Event) {
    this.preferences.metricUnits = event;
  }

  public deleteUser(): void {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = 'Deleting your account';
    refModalConf.componentInstance.confirmationModalMsg = 'Are you sure you want to delete your account?';

    refModalConf.result.then(resp => {
      if (resp) {
        this.userService.deleteUser().then(result => {
          const confirmMessageModal = this.modalService.open(ConfirmationModalComponent, modalOptions);

          confirmMessageModal.componentInstance.onlyMessage = true;
          confirmMessageModal.componentInstance.confirmationModalTitle = 'Deleting your account';
          confirmMessageModal.componentInstance.confirmationModalMsg =
            'We have sent an email with a confirmation link to your e-mail address.';
        });
      } else {
        return false;
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  public saveUserForm(): void {
    this.validateAllFormFields(this.userForm);
    if (this.userForm.valid) {
      this.saving = true;

      this.myUser.displayName = this.userForm.get('displayName').value ? this.userForm.get('displayName').value : this.myUser.displayName;
      this.myUser.userName = this.userForm.get('userName').value;
      this.myUser.bio = this.userForm.get('userBio').value ? this.userForm.get('userBio').value : this.myUser.bio;

      const saveUserApiCalls = [];

      saveUserApiCalls.push(this.userService.updateUser(this.myUser, false));

      if (this.userForm.get('userName').value) {
        this.userService.putUserName(this.myUser)
      }
  
      Promise.all(saveUserApiCalls).then(() => {
        this.saving = false;
  
        this.setAlertMessage(
          'success',
          'Your user data has been updated!',
          10000
        );
  
        if (this.userLocation && this.userLocation.address) {
          this.userService.setLocationAddress(this.userLocation.address);
        }
  
        for (const [key, value] of Object.entries(this.preferences)) {
          this.userService.putUserPreference(key, value ? 1 : 0).then((user) => {
            console.log('user: ', user);
          })
          .catch(err => {
            console.log('error: ', err);
          });
        }
      })
      .catch(err => {
        this.saving = false;
        const errorCode = (err.error && err.error.code) ? err.error.code : null;
        let message: string;
  
        switch (errorCode) {
          case 'ALREADY_EXISTS':
            message = 'This user name is already taken. Please try with different one.';
            break;
          default:
            message = err.error.message || 'There was an unexpected error. Please try again.';
            break;
        }
  
        this.setAlertMessage(
          'error',
          message,
          10000
        );
        console.error('There was and error while saving user profile', err.error || err);
      });
    }
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  private createUserForm(): void {
    this.userForm = this.fb.group({
      displayName: [this.myUser.displayName || '', [Validators.required, Validators.minLength(2)]],
      userName: [this.myUser.userName || '',[]],
      userBio: [this.myUser.bio || '']
    });

    const usernameField = this.userForm.get('userName');

    if (this.myUser.userName) {
      usernameField.setValidators([
        Validators.required,
        Validators.minLength(2),
        NonNumericValidator(),
        Validators.maxLength(15),
        Validators.pattern('^[a-zA-Z0-9]*$')])
    } else {
      usernameField.setValidators([
        Validators.minLength(2),
        NonNumericValidator(),
        Validators.maxLength(15),
        Validators.pattern('^[a-zA-Z0-9]*$')
      ]);
    }
    usernameField.setAsyncValidators(UsernameExistValidation(this.userService));
  }

  private assignPreference(key: string, user: any): any {
    return (user.preferences && user.preferences[key] === 1) ? true : false;
  }

  private setAlertMessage(type: string, message: string, timeout?: number): void {
    this.alert = {
        type,
        message,
        timeout: 5000
    };
  }

  private validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            this.validateAllFormFields(control);
        }
    });
  }
}

