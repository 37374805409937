<app-header></app-header>
<section class="notd__grid user-profile-page wrapper--centered wrapper--organized">
  <user-navigation-menu></user-navigation-menu>
  <section class="user-profile-page__content">
    <div class="user-profile-page__content__profile">
      <div class="user-profile-page__content__profile__avatar">
        <image-editor
          (uploaded)="savedUserAvatar($event)"
          [currentImg]="myUser.profileImageUrl || 'assets/svg/user.svg'"
          [imgHeight]="200"
          [imgWidth]="200"
          [customClass]="myUser.profileImageUrl ? 'user-image-avatar' : 'user-image-avatar-default'"
          [lazyLoading]="{enable: enableLazyLoadingImgAvatar, mainThumb: myUser.profileImageUrl, thumbs: myUser.profileImgThbs}"
          [imgAlt]="myUser.displayName"></image-editor>
        <span class="error-message" *ngIf="avatarErrors.limit">File size exceeds limit of 3MB</span>
        <span class="error-message" *ngIf="avatarErrors.other">{{avatarErrors.other}}</span>
      </div>
      <form
        [formGroup]="userForm"
        novalidate
        class="user-profile-page__content__profile__form">
        <alert-message [alert]="alert"></alert-message>
        <div class="display--flex">
          <div
            data-cy="display-name-input"
            class="user-profile-page__content__profile__form__input-field split--50p m--r--20">
            <label class="user-profile-page__content__profile__form__input-field__label">Display name</label>
            <input
              formControlName="displayName"
              id="displayNameId"
              type="text"
              trimText
              class="user-profile-page__content__profile__form__input-field__field"
              placeholder="Display name"
              name="displayName">
            <div
              class="user-profile-page__content__profile__form__errors"
              *ngIf="userForm.get('displayName').touched && userForm.get('displayName').invalid">
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('displayName').hasError('required')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                Please enter your display name.
              </span>
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('displayName').hasError('minlength')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                Your display name should contain min 2 characters.
              </span>
            </div>
          </div>
          <div
            data-cy="user-name-input"
            class="user-profile-page__content__profile__form__input-field split--50p">
            <label class="user-profile-page__content__profile__form__input-field__label">User name</label>
            <input
              triggers="mouseenter:mouseleave"
              ngbPopover="Used in comments and if someone wishes to invite your user to a community or debate thread"
              popoverTitle=""
              i18n-ngbPopover = "@@USER-008"
              id="userNameId" type="text"
              trimText
              class="user-profile-page__content__profile__form__input-field__field"
              placeholder="User name"
              name="userName"
              formControlName="userName"/>
            <div
              class="user-profile-page__content__profile__form__errors"
              *ngIf="userForm.get('userName').touched && userForm.get('userName').invalid">
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('userName').hasError('required')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                Please enter your user name.
              </span>
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('userName').hasError('pattern')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                Your user name shoudn't contain any spaces and must be alphanumeric.
              </span>
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('userName').hasError('minlength') || userForm.get('userName').hasError('maxlength')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                Your user name shoudn't be less than 2 and more than 15 characters.
              </span>
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('userName').hasError('duplicatedUsername')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                This user name is already taken. Please try a different one.
              </span>
              <span
                class="user-profile-page__content__profile__form__errors__error"
                *ngIf="userForm.get('userName').hasError('nonNumeric')">
                <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
                Your user name must contain only non-numeric characters.
              </span>
            </div>
          </div>
        </div>
        <div class="user-profile-page__content__profile__form__input-field">
          <label class="user-profile-page__content__profile__form__input-field__label">Your biographical info</label>
          <textarea
            name="bio"
            formControlName="userBio"
            placeholder="Your biography"
            class="user-profile-page__content__profile__form__input-field__field textarea"></textarea>
        </div>
        <div class="user-profile-page__content__profile__form__input-field user-email">
          <label class="user-profile-page__content__profile__form__input-field__label">E-mail</label>
          <span
            class="user-email__address"
            data-cy="email-my-account"
            href="#">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/></svg>
              {{myUser.email1}}
          </span>
          <a
            class="user-email__info"
            [href]="transactEmail"
            title="Visit Transact website to change your settings!">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 496.158 496.158" style="enable-background:new 0 0 496.158 496.158;" xml:space="preserve">
                <path style="fill:#25B7D3;" d="M496.158,248.085c0-137.022-111.069-248.082-248.075-248.082C111.07,0.003,0,111.063,0,248.085
                    c0,137.001,111.07,248.07,248.083,248.07C385.089,496.155,496.158,385.086,496.158,248.085z"/>
                <g>
                    <path style="fill:#FFFFFF;" d="M315.249,359.555c-1.387-2.032-4.048-2.755-6.27-1.702c-24.582,11.637-52.482,23.94-57.958,25.015
                        c-0.138-0.123-0.357-0.348-0.644-0.737c-0.742-1.005-1.103-2.318-1.103-4.015c0-13.905,10.495-56.205,31.192-125.719
                        c17.451-58.406,19.469-70.499,19.469-74.514c0-6.198-2.373-11.435-6.865-15.146c-4.267-3.519-10.229-5.302-17.719-5.302
                        c-12.459,0-26.899,4.73-44.146,14.461c-16.713,9.433-35.352,25.41-55.396,47.487c-1.569,1.729-1.733,4.314-0.395,6.228
                        c1.34,1.915,3.825,2.644,5.986,1.764c7.037-2.872,42.402-17.359,47.557-20.597c4.221-2.646,7.875-3.989,10.861-3.989
                        c0.107,0,0.199,0.004,0.276,0.01c0.036,0.198,0.07,0.5,0.07,0.933c0,3.047-0.627,6.654-1.856,10.703
                        c-30.136,97.641-44.785,157.498-44.785,182.994c0,8.998,2.501,16.242,7.432,21.528c5.025,5.393,11.803,8.127,20.146,8.127
                        c8.891,0,19.712-3.714,33.08-11.354c12.936-7.392,32.68-23.653,60.363-49.717C316.337,364.326,316.636,361.587,315.249,359.555z"/>
                    <path style="fill:#FFFFFF;" d="M314.282,76.672c-4.925-5.041-11.227-7.597-18.729-7.597c-9.34,0-17.475,3.691-24.176,10.971
                        c-6.594,7.16-9.938,15.946-9.938,26.113c0,8.033,2.463,14.69,7.32,19.785c4.922,5.172,11.139,7.794,18.476,7.794
                        c8.958,0,17.049-3.898,24.047-11.586c6.876-7.553,10.363-16.433,10.363-26.393C321.646,88.105,319.169,81.684,314.282,76.672z"/>
                </g>
            </svg>
            To change your email, set it on your transact profile
          </a>
        </div>
        <div class="user-profile-page__content__profile__form__section user-notifications">
          <h2 class="user-profile-page__content__profile__form__section__headline">Notifications</h2>
          <label
            class="user-notifications__checkbox"
            data-cy="checkbox-email-notification">
              <svg *ngIf="preferences.emailNotifications" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M10,13.5857864 L15.2928932,8.29289322 L16.7071068,9.70710678 L10,16.4142136 L6.29289322,12.7071068 L7.70710678,11.2928932 L10,13.5857864 Z" fill-rule="evenodd"/></svg>
              <svg *ngIf="!preferences.emailNotifications" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z" fill-rule="evenodd"/></svg>
              <input type="checkbox"
                name="emailNotificationsCheck"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="preferences.emailNotifications">
              <span i18n="@@USER-005">Email me when I receive a notification</span>
          </label>
          <label
            class="user-notifications__checkbox"
            data-cy="checkbox-email-comment">
            <svg *ngIf="preferences.notifyPostComments" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M10,13.5857864 L15.2928932,8.29289322 L16.7071068,9.70710678 L10,16.4142136 L6.29289322,12.7071068 L7.70710678,11.2928932 L10,13.5857864 Z" fill-rule="evenodd"/></svg>
            <svg *ngIf="!preferences.notifyPostComments" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z" fill-rule="evenodd"/></svg>
            <input 
              type="checkbox"
              name="preferencesNotifyPostCommentsCheck"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="preferences.notifyPostComments">
            <span i18n="@@USER-006">Email me when I receive a comment on a note</span>
          </label>
        </div>
        <div class="user-profile-page__content__profile__form__section user-location">
          <h2 class="user-profile-page__content__profile__form__section__headline">Location</h2>
          <notd-address [simple]="true" [autocompleteDisplay] = "'short'" (geoLocatorEmitter)="geoLocatorEvent($event)" [address]="userLocation"></notd-address>
          <div class="user-location__distance">
            <span class="user-location__distance__label" i18n="@@USER-007">Display distance in: </span>
            <span data-cy="switch-miles" [ngClass]="{'user-location__distance--selected': !preferences.metricUnits}" i18n="@@GLOBAL-MILES">Miles</span>
            <toggle-switch [checked]="preferences.metricUnits" (change)="toggleDistanceEvent($event)"></toggle-switch>
            <span data-cy="switch-kilometers" [ngClass]="{'user-location__distance--selected': preferences.metricUnits}" i18n="@@GLOBAL-KILOMETERS">Kilometers</span>
          </div>
        </div>
        <div class="user-profile-page__content__profile__form__section user-delete">
          <h2 class="user-profile-page__content__profile__form__section__headline">Delete your account</h2>
          If you want to delete your account please <a (click)="deleteUser()" title="Delete yout account" class="user-delete__link">click here</a> to start the procedure of removing your profile from the Notd service.
          <p
            class="user-delete__info">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 496.158 496.158" style="enable-background:new 0 0 496.158 496.158;" xml:space="preserve">
                <path style="fill:#25B7D3;" d="M496.158,248.085c0-137.022-111.069-248.082-248.075-248.082C111.07,0.003,0,111.063,0,248.085
                    c0,137.001,111.07,248.07,248.083,248.07C385.089,496.155,496.158,385.086,496.158,248.085z"/>
                <g>
                    <path style="fill:#FFFFFF;" d="M315.249,359.555c-1.387-2.032-4.048-2.755-6.27-1.702c-24.582,11.637-52.482,23.94-57.958,25.015
                        c-0.138-0.123-0.357-0.348-0.644-0.737c-0.742-1.005-1.103-2.318-1.103-4.015c0-13.905,10.495-56.205,31.192-125.719
                        c17.451-58.406,19.469-70.499,19.469-74.514c0-6.198-2.373-11.435-6.865-15.146c-4.267-3.519-10.229-5.302-17.719-5.302
                        c-12.459,0-26.899,4.73-44.146,14.461c-16.713,9.433-35.352,25.41-55.396,47.487c-1.569,1.729-1.733,4.314-0.395,6.228
                        c1.34,1.915,3.825,2.644,5.986,1.764c7.037-2.872,42.402-17.359,47.557-20.597c4.221-2.646,7.875-3.989,10.861-3.989
                        c0.107,0,0.199,0.004,0.276,0.01c0.036,0.198,0.07,0.5,0.07,0.933c0,3.047-0.627,6.654-1.856,10.703
                        c-30.136,97.641-44.785,157.498-44.785,182.994c0,8.998,2.501,16.242,7.432,21.528c5.025,5.393,11.803,8.127,20.146,8.127
                        c8.891,0,19.712-3.714,33.08-11.354c12.936-7.392,32.68-23.653,60.363-49.717C316.337,364.326,316.636,361.587,315.249,359.555z"/>
                    <path style="fill:#FFFFFF;" d="M314.282,76.672c-4.925-5.041-11.227-7.597-18.729-7.597c-9.34,0-17.475,3.691-24.176,10.971
                        c-6.594,7.16-9.938,15.946-9.938,26.113c0,8.033,2.463,14.69,7.32,19.785c4.922,5.172,11.139,7.794,18.476,7.794
                        c8.958,0,17.049-3.898,24.047-11.586c6.876-7.553,10.363-16.433,10.363-26.393C321.646,88.105,319.169,81.684,314.282,76.672z"/>
                </g>
            </svg>
            Deleting your account will also remove all your streams, notes and comments.
          </p>
        </div>
        <div class="user-profile-page__content__profile__form__button">
          <button
            class="notd__button--v2 button--green"
            data-cy="my-account-save-btn"
            (click)="saveUserForm()"
            [disabled]="saving"
            [ngClass]="{'button-spinner' : saving}"
            i18n="@@GLOBAL-SAVE">Save settings</button>
        </div>
      </form>
    </div>
    <div class="user-profile-page__content__activity">
      <h2 class="user-profile-page__content__h2">My Activity</h2>
      <activity-table></activity-table>
    </div>
  </section>
</section>
<app-footer></app-footer>
